<template>
    <div class="border">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <EntireCheck class="top" :dataSource="totalserviceList" @handleStaticCircleClick="handleStaticCircleClick" :isCheckFinished="isCheckFinished"></EntireCheck>
        <DetailMsg class="bottom" :isStaticCircleClick="isStaticCircleClick" @handleCheckFinashed="handleCheckFinashed"></DetailMsg>
    </div>
</template>
<script>
import EntireCheck from '../entire_check'
import DetailMsg from './detail_msg'
import { mapGetters, mapActions } from 'vuex'
import * as TYPES from "@store/modules/mutation-type.js"
export default {
    name: "service_state",
    data() {
        return {
            isStaticCircleClick: false,  // 是否已开始全面检测
            isCheckFinished: false,  // 全面检测是否已完成
            breadlist: [
                {
                    path: '',
                    name: '数据管理与接入'
                }, {
                    path: '',
                    name: '运行状态'
                }
            ],
            itemName: '共享服务状态',
        }
    },
    components: {
        EntireCheck,
        DetailMsg
    },
    computed: {
        ...mapGetters({
            totalserviceList: TYPES.totalserviceList
        })
    },
    methods: {
        ...mapActions([
            "GetShareServiceRunningStatus"
        ]),
        // 监听全面检测
        handleStaticCircleClick() {
            this.isStaticCircleClick = true;
        },
        // 全面检测完成
        handleCheckFinashed(val) {
            if (val) {
                this.isStaticCircleClick = false;
                this.isCheckFinished = true;
            } else {
                this.isCheckFinished = false;
            }
        }
    },
    watch: {
    },
    created() {
        this.GetShareServiceRunningStatus()
    }
}
</script>
<style scoped>
.border {
    height: 100%;
}

.top {
    height: 210px;
    margin: -1px 0px 20px;
}

.bottom {
    height: calc(100% - 290px);
}
</style>
