<template>
    <div>
        <div class="dm-item" v-for="(item,index) in eqCheckList" :key="`${index}`">
            <div class="border">
                <div class="content" v-if="item.sfyc=='1'">
                    <i class="servicestate el-icon-info"></i>
                    <span class="name">{{item.LX}}</span>
                    <div class="viewAbnormal" @click="viewAbnormal(item,index)">
                        <span class="viewtext">查看异常</span>
                        <i v-if="isOpen!=index" class="servicestate el-icon-caret-bottom"></i>
                        <i v-if="isOpen==index" class="servicestate el-icon-caret-top"></i>
                    </div>
                </div>
                <div class="content1" v-if="item.sfyc=='0'">
                    <i class="servicestate el-icon-circle-check"></i>
                    <span class="name1">{{item.LX}}</span>
                </div>
                <div class="content">
                    <span class="time">{{item.time}}（全部检测）</span>
                </div>
                <div>
                    <el-button :class="item.checkState==2?'dm-btn-ing':'dm-btn-ed'" :type="item.checkState==2?'':'info'" round size="medium" style="width: 120px;" :disabled="item.checkState!=0 || isStaticCircleClick" @click="handleChange(item)">
                        <i class="el-icon-loading" v-show="item.checkState == 2"></i>{{item.checkState==2?" 正在检测 ":(item.checkState==3?"已检测":(item.checkState==1?"待检测":"检测"))}}</el-button>
                </div>
            </div>
            <div :class="ele.JRJG=='正常'?'border11':'border1'" v-for="(ele,inx) in ResultList" :key="inx">
                <div v-if="isOpen==index">
                    <div class="content">
                        <span class="abnormaltext">接入位置：{{ele.WZ}}</span>
                    </div>
                    <div class="content">
                        <span class="abnormaltext">检查时间：{{ele.JCKSSJ}}</span>
                    </div>
                    <div class="content">
                        <span class="abnormaltext">接入结果：{{ele.JRJG}}</span>
                    </div>
                    <div class="content">
                        <i class='el-icon-warning' style="color:red;cursor: pointer;" v-if="ele.JRJG!='正常'" title="异常原因" @click="getDetail(ele)"></i>
                    </div>
                    <div class="content">
                        <img src="@image/dataService_management/link.png">
                        <span class="txt" @click="visit(ele.GRABMAINURL)">访问</span>
                    </div>
                    <div class="content"></div>
                    <div class="content"></div>
                </div>
            </div>
        </div>
        <el-dialog title="异常原因及建议" :visible.sync="dialogTableVisible">
            <el-table :data="gridData">
                <el-table-column property="YCLX" label="异常类型" width="180" :show-overflow-tooltip='true'></el-table-column>
                <el-table-column property="YCNR " label="异常内容" :show-overflow-tooltip='true'></el-table-column>
                <el-table-column property="JYFA" label="建议方案" :show-overflow-tooltip='true'></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import * as TYPES from "@store/modules/mutation-type.js"

var initNum = 0;

export default {
    name: "detail-msg",
    data() {
        return {
            isOpen: null,
            warning: true,
            eqCheckList: [],
            eqCheckListLength: 1,
            gridData: [],
            dialogTableVisible: false

        }
    },
    components: {

    },
    props: {
        isStaticCircleClick: {
            type: Boolean,
            default: false
        },  // 是否开始全面检测
    },
    computed: {
        ...mapGetters({
            zdqdList: TYPES.zdqdList,
            ResultList: TYPES.ResultList,
        }),
    },
    methods: {
        ...mapMutations({
            SET_ABNORMALCOUNTWHOLE: TYPES.SET_ABNORMALCOUNTWHOLE,
        }),
        ...mapActions([
            "GetShareSerLxResultList",
            "CheckShareServiceSingleDataIsNormal",
            "GetCheckTypeDetail"
        ]),
        viewAbnormal(item, index) {
            this.isOpen == index ? this.isOpen = null : this.isOpen = index;
            this.GetShareSerLxResultList({ lx: item.LX })
        },
        // 点击检测按钮
        handleChange(val) {
            val.checkState = 2;


            this.logInOrder(val);
        },
        async logInOrder(val) {
            let tmpNum = 0;
            let isZcOrYc = null;
            for (const item of val.ZDLIST) {
                ++tmpNum;
                let response = await this.CheckShareServiceSingleDataIsNormal({ jcdid: item.ZDID });
                if (response.IsSuccess) {
                    ++val.NormalCount;
                    isZcOrYc = 0;
                } else {
                    ++val.abnormalCount;
                    isZcOrYc = 1;
                }
                if (this.isStaticCircleClick == true) {
                    this.SET_ABNORMALCOUNTWHOLE(isZcOrYc);
                }
                if (val.ZDLIST.length == tmpNum) {
                    if (this.isStaticCircleClick == true) {
                        // 设置已检测完的按钮的状态为：“已检测”
                        val.checkState = 3;
                        this.handleSingleStatusRun();
                    } else {
                        val.checkState = 0;
                    }
                    isZcOrYc = null;
                }
            }
        },
        handleSingleStatusRun() {
            if (initNum < this.eqCheckListLength - 1) {
                initNum = initNum + 1;
                this.handleChange(this.eqCheckList[initNum]);
                this.$emit("handleCheckFinashed");
            } else {
                initNum = 99;
                this.$emit("handleCheckFinashed", true);

                // 设置全部检测按钮的状态为默认状态：“检测”
                this.eqCheckList.forEach(item => {
                    item.checkState = 0;
                })
            }
        },
        visit(val) {
            if (!val) return;
            let newWin = window.open("about:blank");
            newWin.location.href = val;
        },
        async getDetail(val) {
            let result = await this.GetCheckTypeDetail({
                yclxid: val.JRJGLXID
            })
            this.gridData = result.ResultValue
            this.dialogTableVisible = true
        }
    },
    watch: {
        zdqdList() {
            if (!this.zdqdList || this.zdqdList.length == 0) return;
            this.eqCheckList = this.zdqdList
            this.eqCheckListLength = this.eqCheckList.length;
        },
        isStaticCircleClick() {
            if (!this.zdqdList || this.zdqdList.length == 0) return;
            if (this.isStaticCircleClick == true) {
                this.SET_ABNORMALCOUNTWHOLE(null);

                // 设置全部检测按钮的状态为：“待检测”
                this.eqCheckList.forEach(ele => {
                    ele.checkState = 1;
                })

                this.handleChange(this.eqCheckList[0]);
                this.$emit("handleCheckFinashed");
                initNum = 0;
            }

        },
        ResultList() {

        }
    },
    created() {

    }
}
</script>
<style scoped>
.dm-item {
    background: #fff;
}

.border {
    height: 75px;
    margin: 15px 0px 0px;
    border: 1px solid #D5D5D5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 0 20px;
}

.border1 {
    height: 47px;
    border: 1px solid #D5D5D5;
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 0 20px;
}

.border1 .abnormaltext {
    font-size: 14px;
    color: red;
    display: block;
}

.border11 {
    height: 47px;
    border: 1px solid #D5D5D5;
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 0 20px;
}

.border11 .abnormaltext {
    font-size: 14px;
    color: green;
    display: block;
}

.content {
    flex: 1;
}

.content1 {
    flex: 1;
}

.name {
    color: #FF9400;
    font-size: 18px;
}

.name1 {
    color: #00d458;
    font-size: 18px;
}

.viewtext {
    color: #FF9400;
    font-size: 14px;
}

.time {
    font-size: 14px;
    color: #7e7e7e;
}

.viewAbnormal {
    display: inline-block;
    margin-left: 100px;
    cursor: pointer;
}

.txt {
    color: cornflowerblue;
    font-size: 15px;
    cursor: pointer;
}

.txt:hover {
    color: #FF9400;
}

.el-button--info {
    background-color: #a46c17;
}

.servicestate.el-icon-info {
    font-size: 20px;
    color: #FF9400;
    margin-right: 15px;
}

.servicestate.el-icon-caret-bottom {
    font-size: 20px;
    color: #FF9400;
}

.servicestate.el-icon-caret-top {
    font-size: 20px;
    color: #FF9400;
}

.servicestate.el-icon-circle-check {
    font-size: 20px;
    color: #00d458;
    margin-right: 15px;
}

.el-button--info,
.dm-btn-ed.el-button.is-disabled {
    font-size: 14px;
    color: #fff;
    border-color: #2a63d6;
    background-color: #2a63d6;
}

.dm-btn-ing.el-button.is-disabled {
    font-size: 14px;
    color: #2a63d6;
    border-color: #2a63d6;
    background-color: #fff;
}
</style>
