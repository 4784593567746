<template>
    <div class="ec-border">
        <div class="ec-checkTime">
            <span class="ec-time">{{ localToday }}</span>
            <span class="ec-time">{{ currentTime }}</span>
            <span class="ec-time">{{ weekToday }}</span>
        </div>
        <div class="ec-content">
            <div class="ec-top">
                <p class="ec-p-big">{{ dataType }}数据接入情况</p>
                <p class="ec-p-small">
                    共 {{ typeTotal }} 项 {{ equipTotal }} 个设备
                </p>
                <el-button
                    class="ec-qmjc"
                    :disabled="isProgressShow && !isCheckFinished"
                    @click="handleStaticCircleClick"
                    >{{ labelName }}</el-button
                >
            </div>
            <div class="ec-middle" v-show="isProgressShow">
                <div
                    class="test"
                    v-show="(normalNum + specialNum) / equipTotal > 0"
                    :style="{
                        width: `${((normalNum + specialNum) / equipTotal) *
                            100}%`,
                    }"
                ></div>
                <div
                    class="ec-progress-right"
                    :style="{
                        width: `${((equipTotal - normalNum - specialNum) /
                            equipTotal) *
                            100}%`,
                    }"
                ></div>
            </div>
            <div class="ec-bottom">
                <div class="ec-ibord">
                    <!-- <i class="ec-runningstate el-icon-success"></i> -->
                    <img class="ec-status-icon" :src="zhengchangIcon" />
                    <span class="ec-normal-text"
                        >正常个数：
                        <span class="ec-normal-count">
                            <span class="ec-normal-num">{{ normalNum }}</span
                            >个</span
                        >
                    </span>
                </div>
                <div class="ec-ibord">
                    <!-- <i class="ec-runningstate el-icon-warning"></i> -->
                    <img class="ec-status-icon" :src="yichangIcon" />
                    <span class="ec-special-text"
                        >异常个数：
                        <span class="ec-normal-count">
                            <span class="ec-normal-num">{{ specialNum }}</span
                            >个</span
                        >
                    </span>
                </div>
                <el-button
                    class="ec-ycsjjrxq"
                    v-if="isEqAll && specialNum > 0"
                    size="mini"
                    @click="gotoDetail()"
                    >异常数据接入详情</el-button
                >
                <span class="ec-lasttime">
                    上次检测时间：{{ lasttime }} （全面检测）
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import * as TYPES from "@store/modules/mutation-type.js";

var normalTotal = 0;
var abnormalTotal = 0;

export default {
    name: "entire_check",
    data() {
        return {
            localToday: "",
            weekToday: "",
            currentTime: "",
            typeTotal: 0, // 项
            equipTotal: 0, // 设备数量
            normalNum: 0,
            specialNum: 0,
            isEqAll: true,
            dataType: "设备",
            labelName: "全面检测",
            // cicleClicked: false,
            lasttime: "",
            zhengchangIcon: require("@image/dataService_management/zhengchang.png"),
            yichangIcon: require("@image/dataService_management/yichang.png"),
            isProgressShow: false,
        };
    },
    computed: {
        ...mapGetters({
            abnormalCountWhole: TYPES.abnormalCountWhole,
            SBQDLIST: TYPES.SBQDLIST, //设备运行状态
            zdqdList: TYPES.zdqdList, //共享服务状态
        }),
    },
    created() {
        setTimeout(() => {
            this.run();
        }, 1);
        if (this.$route.name == "service_state") {
            this.dataType = "服务";
            this.isEqAll = false;
        } else if (this.$route.name == "equipment_state") {
            this.dataType = "设备";
            this.isEqAll = true;
        }
    },
    props: {
        dataSource: {
            default: null,
            type: Object,
        },
        isCheckFinished: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        dataSource() {
            let _this = this;
            if (
                !_this.dataSource ||
                !_this.dataSource.COUNT ||
                _this.dataSource.COUNT.length == 0
            )
                return;
            _this.dataSource.COUNT.forEach((item) => {
                if (item.JRJG == "正常") {
                    _this.normalNum = item.COUNT;
                } else {
                    _this.specialNum = item.COUNT;
                }
            });
            // _this.equipTotal = _this.normalNum + _this.specialNum;
            _this.lasttime = _this.dataSource.SCJCSJ;
            // return;
            if (_this.$route) {
                if (_this.$route.redirectedFrom) return;
                // _this.handleStaticCircleClick()
            }
        },
        SBQDLIST() {
            let _this = this;
            _this.typeTotal = 0;
            _this.equipTotal = 0;
            let sbqdcopy = JSON.parse(JSON.stringify(_this.SBQDLIST));
            sbqdcopy = sbqdcopy.filter(
                ({ SBLX }) =>
                    SBLX !== "10" &&
                    SBLX !== "13" &&
                    SBLX !== "16" &&
                    SBLX !== "3"
            );
            if (sbqdcopy && sbqdcopy.length > 0) {
                _this.typeTotal = sbqdcopy.length;
                sbqdcopy.forEach((item) => {
                    if (item && item.SBLIST && item.SBLIST.length > 0) {
                        _this.equipTotal =
                            _this.equipTotal + item.SBLIST.length;
                    }
                });
            }
        },
        zdqdList() {
            let _this = this;
            _this.typeTotal = 0;
            _this.equipTotal = 0;
            let sbqdcopy = JSON.parse(JSON.stringify(_this.zdqdList));
            if (sbqdcopy && sbqdcopy.length > 0) {
                _this.typeTotal = sbqdcopy.length;
                sbqdcopy.forEach((item) => {
                    if (item && item.ZDLIST && item.ZDLIST.length > 0) {
                        _this.equipTotal =
                            _this.equipTotal + item.ZDLIST.length;
                    }
                });
            }
        },
        isCheckFinished() {
            if (this.isCheckFinished == true) {
                // this.cicleClicked = false;
                this.labelName = "全面检测";
                this.isProgressShow = false;
            }
        },
        abnormalCountWhole() {
            if (this.abnormalCountWhole === 1) {
                abnormalTotal++;
                this.specialNum = abnormalTotal;
                this.SET_ABNORMALCOUNTWHOLE(null);
                // this.equipTotal = this.specialNum + this.normalNum;
            } else if (this.abnormalCountWhole === 0) {
                normalTotal++;
                this.normalNum = normalTotal;
                this.SET_ABNORMALCOUNTWHOLE(null);
                // this.equipTotal = this.specialNum + this.normalNum;
            }
        },
    },
    methods: {
        ...mapMutations({
            SET_ABNORMALCOUNTWHOLE: TYPES.SET_ABNORMALCOUNTWHOLE,
        }),
        run() {
            let week = [
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
            ];
            let d = new Date();
            this.localToday =
                d.getFullYear() +
                "年" +
                (d.getMonth() + 1) +
                "月" +
                d.getDate() +
                "日";
            this.weekToday = week[d.getDay()];
            let hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
            let minute =
                d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
            let second =
                d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
            this.currentTime = hour + ":" + minute + ":" + second;
            setInterval(() => {
                let d = new Date();
                let hour =
                    d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
                let minute =
                    d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
                let second =
                    d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
                this.currentTime = hour + ":" + minute + ":" + second;
            }, 1000);
        },
        gotoDetail() {
            // this.$router.push({ name: "equipment_total_result" })
            this.$emit("gotoDetail", true);
        },
        // 1.点击全面检测，依次进行每一项的检测
        handleStaticCircleClick() {
            abnormalTotal = 0;
            normalTotal = 0;
            this.specialNum = 0;
            this.normalNum = 0;
            // this.equipTotal = 0;
            // this.cicleClicked = true;
            this.labelName = "全面检测中";
            this.isProgressShow = true;
            this.$emit("handleStaticCircleClick");
        },
    },
};
</script>
<style scoped>
.ec-border {
    height: 100%;
    background: #fff;
    box-shadow: 1px 1px 5px #ccc;
}

.ec-content {
    height: calc(100% - 57px);
}

.ec-top {
    height: 75px;
    margin: 0 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ec-middle {
    height: 16px;
    margin: 0 25px;
    display: flex;
    border-radius: 20px;
    background: #eee;
}

.test {
    margin: 0px auto;
    /* width: 250px; */
    height: 16px;
    border-radius: 20px;
    background-color: #31dee5;
    position: relative;
}

.test:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    content: "";
    background: -webkit-gradient(
        linear,
        0 100%,
        100% 0,
        color-stop(0.25, #25ccd8),
        color-stop(0.25, transparent),
        color-stop(0.5, transparent),
        color-stop(0.5, #25ccd8),
        color-stop(0.75, #25ccd8),
        color-stop(0.75, transparent),
        to(transparent)
    );
    background: -webkit-gradient(
        linear,
        0 100%,
        100% 0,
        color-stop(0.25, #25ccd8),
        color-stop(0.25, transparent),
        color-stop(0.5, transparent),
        color-stop(0.5, #25ccd8),
        color-stop(0.75, #25ccd8),
        color-stop(0.75, transparent),
        to(transparent)
    );
    background: -webkit-linear-gradient(
        45deg,
        #25ccd8 25%,
        transparent 25%,
        transparent 50%,
        #25ccd8 50%,
        #25ccd8 75%,
        transparent 75%,
        transparent
    );
    background: -moz-linear-gradient(
        45deg,
        #25ccd8 25%,
        transparent 25%,
        transparent 50%,
        #25ccd8 50%,
        #25ccd8 75%,
        transparent 75%,
        transparent
    );
    background: -o-linear-gradient(
        45deg,
        #25ccd8 25%,
        transparent 25%,
        transparent 50%,
        #25ccd8 50%,
        #25ccd8 75%,
        transparent 75%,
        transparent
    );
    background: linear-gradient(
        45deg,
        #25ccd8 25%,
        transparent 25%,
        transparent 50%,
        #25ccd8 50%,
        #25ccd8 75%,
        transparent 75%,
        transparent
    );
    -webkit-animation: twb-bar 2s linear infinite;
    -moz-animation: twb-bar 2s linear infinite;
    -ms-animation: twb-bar 2s linear infinite;
    -o-animation: twb-bar 2s linear infinite;
    animation: twb-bar 2s linear infinite;
    -webkit-background-size: 40px 40px;
    -moz-background-size: 40px 40px;
    -o-background-size: 40px 40px;
    background-size: 40px 40px;
}

@-webkit-keyframes twb-bar {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 40px 0;
    }
}

@-moz-keyframes twb-bar {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 40px 0;
    }
}

@-ms-keyframes twb-bar {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 40px 0;
    }
}

@-o-keyframes twb-bar {
    from {
        background-position: 40px 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes twb-bar {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 40px 0;
    }
}

.ec-progress-right {
    height: 100%;
}

.ec-bottom {
    position: relative;
    height: 60px;
    margin: 0 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ec-checkTime {
    width: calc(100% - 50px);
    margin: 0px 25px;
    border-bottom: 2px solid #e5e5e5;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.ec-p-big {
    margin: 15px 25px 0 0;
    padding-bottom: 10px;
    font-size: 28px;
    color: #333;
}

.ec-p-small {
    margin: 15px 50px 0 0;
    font-size: 18px;
    color: #343434;
}

.ec-qmjc.el-button {
    padding: 12px 40px;
    border-radius: 30px;
    font-size: 18px;
    color: #fff;
    background: #2a63d6;
}

.ec-ycsjjrxq.el-button {
    padding: 7px 15px;
    border-radius: 10px;
    border-color: #ff9065;
    font-size: 14px;
    color: #fdfffe;
    background: #ff9065;
}

.ec-ycsjjrxq {
    margin-left: -30px;
}

.ec-ibord {
    line-height: 2;
    margin: 0 50px 0 0;
}

.ec-time {
    height: 55px;
    margin-right: 10px;
    line-height: 55px;
    font-size: 20px;
    color: #333;
}

.ec-runningstate.el-icon-success {
    font-size: 22px;
    color: #58cf8b;
    margin-right: 5px;
    margin-bottom: -5px;
}

.ec-runningstate.el-icon-warning {
    font-size: 22px;
    color: #ff9066;
    margin-right: 5px;
}

.ec-runningstate.el-icon-info {
    font-size: 22px;
    color: #1d88ee;
    margin-right: 5px;
}

.ec-status-icon {
    margin: 0 8px 10px 0;
}

.ec-normal-text {
    color: #2ec290;
}

.ec-special-text {
    color: #ff9066;
}

.ec-normal-count {
    color: #333;
}

.ec-normal-num {
    margin: 0 5px 0 10px;
    font-size: 20px;
    font-weight: bold;
}

.ec-lasttime {
    position: absolute;
    right: 0px;
}
</style>
